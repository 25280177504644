import React, { useState, useEffect, useRef } from "react";
import usePartySocket from "partysocket/react";
import ReactPlayer from "react-player";
import { createRoot } from "react-dom/client";

function App() {
  const [queue, setQueue] = useState<string[]>([]);
  const [currentVideo, setCurrentVideo] = useState<string>("");
  const [playbackTime, setPlaybackTime] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);

  const ws = usePartySocket({
    host: "backrooms-party.apevesting.partykit.dev/",
    room: "backrooms",
    onMessage: (event) => {
      const [command, data] = event.data.split(':');
      switch (command) {
        case "queueUpdated":
          const updatedQueue = JSON.parse(data);
          setQueue(updatedQueue);
          if (updatedQueue.length > 0) {
            setCurrentVideo(updatedQueue[0]);
          }
          break;
        case "currentTime":
          if (playerRef.current) {
            const currentTime = parseFloat(data);
            playerRef.current.seekTo(currentTime, 'seconds');
          }
          break;
      }
    },
  });

  // Sync time with the server periodically
  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        ws.send(`syncTime:${currentTime}`);
      }
    }, 1000); // Sync every second

    return () => clearInterval(interval);
  }, [ws]);

  // Handle video ending
  useEffect(() => {
    if (queue.length > 0 && currentVideo !== queue[0]) {
      setCurrentVideo(queue[0]);
    }
  }, [queue]);

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        url={currentVideo}
        playing={true}
        controls={true}
        onEnded={() => ws.send("videoEnded")}
        onProgress={({ playedSeconds }) => setPlaybackTime(playedSeconds)}
      />
    </div>
  );
}

createRoot(document.getElementById("app")!).render(<App />);